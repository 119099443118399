import { casinoTabs } from "./casinoTabs.data";

export const EzugiGames = {
  ...casinoTabs.roulettetab,
  ...casinoTabs.baccarattab,
  ...casinoTabs.AndarBahartab,
  ...casinoTabs.TeenPattitab,
  ...casinoTabs.Blackjacktab,
  ...casinoTabs.thirtytwoCardtab,
  ...casinoTabs.sevenLuckytab,
  ...casinoTabs.sicbotab,
  ...casinoTabs.Slots,
  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopMegaways: {
      href: "/casino/ezugi/FruitShopMegaways",
      code: "1100094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenCryptex: {
      href: "/casino/ezugi/GoldenCryptex",
      code: "1100147",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTsar: {
      href: "/casino/ezugi/GoldenTsar",
      code: "1100149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GorillaKingdom: {
      href: "/casino/ezugi/GorillaKingdom",
      code: "1100088",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MystryReels: {
      href: "/casino/ezugi/MystryReels",
      code: "1100061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSea: {
      href: "/casino/ezugi/RageoftheSea",
      code: "1100116",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKingMegaways: {
      href: "/casino/ezugi/ReelKingMegaways",
      code: "1100052",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TurnFortune: {
      href: "/casino/ezugi/TurnFortune",
      code: "1100041",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TwinSpinMegaways: {
      href: "/casino/ezugi/TwinSpinMegaways",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WillysChills: {
      href: "/casino/ezugi/WillysChills",
      code: "1100128",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VikingsSlots: {
      href: "/casino/ezugi/VikingsSlots",
      code: "1100124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GemsGoneWild: {
      href: "/casino/ezugi/GemsGoneWild",
      code: "1100145                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GemsGoneWildPowerReels: {
      href: "/casino/ezugi/GemsGoneWildPowerReels",
      code: "1100146                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenLeprechaunMegaways: {
      href: "/casino/ezugi/GoldenLeprechaunMegaways",
      code: "1100148                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    HoardofPoseidon: {
      href: "/casino/ezugi/HoardofPoseidon",
      code: "1100151                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JewelScarabs: {
      href: "/casino/ezugi/JewelScarabs",
      code: "1100152                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleBellsPowerReels: {
      href: "/casino/ezugi/JingleBellsPowerReels",
      code: "1100153                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LegendofAthena: {
      href: "/casino/ezugi/LegendofAthena",
      code: "1100154                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyHalloween: {
      href: "/casino/ezugi/LuckyHalloween",
      code: "1100155                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyLittleDevil: {
      href: "/casino/ezugi/LuckyLittleDevil",
      code: "1100156                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyValentine: {
      href: "/casino/ezugi/LuckyValentine",
      code: "1100157                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyWizard: {
      href: "/casino/ezugi/LuckyWizard",
      code: "1100158                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Masquerade: {
      href: "/casino/ezugi/Masquerade",
      code: "1100159                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MayanGods: {
      href: "/casino/ezugi/MayanGods",
      code: "1100160                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaDragon: {
      href: "/casino/ezugi/MegaDragon",
      code: "1100161                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaJade: {
      href: "/casino/ezugi/MegaJade",
      code: "1100162                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaRise: {
      href: "/casino/ezugi/MegaRise",
      code: "1100163                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MultiplierRiches: {
      href: "/casino/ezugi/MultiplierRiches",
      code: "1100164                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MysteryReelsMegaways: {
      href: "/casino/ezugi/MysteryReelsMegaways",
      code: "1100165                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    NinjaWays: {
      href: "/casino/ezugi/NinjaWays",
      code: "1100166                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PathofDestiny: {
      href: "/casino/ezugi/PathofDestiny",
      code: "1100167                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PersianFortune: {
      href: "/casino/ezugi/PersianFortune",
      code: "1100168                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelHeist: {
      href: "/casino/ezugi/ReelHeist",
      code: "1100169                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
  },

  Roulette: {
    href: "/casino/ezugi/Roulette",
    code: "1000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/poker/Poker01.png", alt: "" },
  },
  SppedAutoRoulette: {
    href: "/casino/ezugi/SppedAutoRoulette",
    code: "221002",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/poker/Poker01.png", alt: "" },
  },
};
