import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { Col, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/neft.png";
import PaymentLogo2 from "../assets/images/payments/imps.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";
import Logo_neww1 from "../assets/images/Crown-Logo.png";
import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../assets/images/providers/ProviderImg2.png";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import ProviderLogo4 from "../assets/images/providers/ProviderImg4.png";
import ProviderLogo5 from "../assets/images/providers/ProviderImg5.jpg";
import ProviderLogo6 from "../assets/images/providers/ProviderImg6.png";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer">
      <div className="providers_sec">
        <Row>
          <Col md={6} xs={12}>
            <div className="provider-mob-border">
              <div className="heading mb-4">
                <div className="text">
                  <h5>Top Providers</h5>
                  <p>
                    Play Your favorite Casino Games with{" "}
                    <span>Top Providers</span>
                  </p>
                </div>
              </div>

              <div className="providers_logo d-flex flex-wrap justify-content-center">
                <a href="/casino/evolution" className="logo_box">
                  <img src={ProviderLogo1} alt="Provider Logo" />
                </a>
                <a href="/casino/pragmatic" className="logo_box">
                  <img src={ProviderLogo2} alt="Provider Logo" />
                </a>
                <a href="casino/ezugi" className="logo_box">
                  <img src={ProviderLogo3} alt="Provider Logo" />
                </a>
                <a href="/casino/supernowa" className="logo_box">
                  <img src={ProviderLogo4} alt="Provider Logo" />
                </a>
                {/* <a href="/casino/onetouch" className="logo_box">
                  <img src={ProviderLogo5} alt="Provider Logo" />
                </a> */}
                <a href="/casino/xpg" className="logo_box">
                  <img src={ProviderLogo6} alt="Provider Logo" />
                </a>
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="">
              <div className="heading payment-heading mb-4 ">
                <div className="text">
                  <h5>Payment Gateway</h5>
                  <p>
                    Accept Process &amp; disburse <span>Digital Payments </span>
                    for your bussiness
                  </p>
                </div>
              </div>

              <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                <a href="/casino/evolution" className="logo_box">
                  <img src={PaymentLogo1} alt="Provider Logo" />
                </a>
                <a href="/casino/pragmatic" className="logo_box payment-height">
                  <img src={PaymentLogo2} alt="Provider Logo" />
                </a>

                <a href="/casino/supernowa" className="logo_box">
                  <img src={PaymentLogo4} alt="Provider Logo" />
                </a>
                <a href="/casino/onetouch" className="logo_box payment-height">
                  <img src={PaymentLogo5} alt="Provider Logo" />
                </a>
                <a href="/casino/supernowa" className="logo_box">
                  <img src={PaymentLogo6} alt="Provider Logo" />
                </a>
                <a href="/casino/onetouch" className="logo_box payment-height">
                  <img src={PaymentLogo7} alt="Provider Logo" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="container">
        <div className="providers_img">
          <a href="javascript:void(0)">
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="javascript:void(0)">
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="javascript:void(0)">
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="javascript:void(0)">
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="footer-links">
          <ul>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
            <li>
              <a href="/contactuss">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicys ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegames">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplays">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrules">Games Rules</a>
            </li>
            <li>
              <a href="/termss">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div className="helpline d-none">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                : "javascript:void(0)"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            WhatsApp Us
          </a>
          {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
        </div>

        <div class="d-flex justify-content-center mt-2 d-none">
          <img
            src={PaymentMod}
            loading="lazy"
            style={{ width: "250px" }}
            alt="Payment Otions"
          />
        </div>

        <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
          <img
            src={Betfair}
            loading="lazy"
            class="mb-3  mx-3 betfair"
            alt="Betfair Logo"
          />
          <a href="javascript:void(0)">
            <img
              src={Gamble18}
              loading="lazy"
              alt="Gamble Responsible"
              class="mb-3 betfair"
            />
          </a>
        </div>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" />
            </li>
          </ul>
        </div>

        <div className="social-icons d-none">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? appDetails?.WHATS_APP_URL
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {" "}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.FACEBOOK != ""
                    ? appDetails?.FACEBOOK
                    : "javascript:void(0)"
                }
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu new_footer position-fixed start-0 bottom-0 z-index-2 w-100 d-none d-md-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                href=""
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                <img src={Exchange} alt="Exchange Icon" />
                <span>Sports book</span>
              </a>
            </li>
            <li>
              <a className="active " href="/">
                <img src={Logo_neww1} />
                <span>Home</span>
              </a>
            </li>

            <li>
              <a
                href=""
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                <GamesIcon />
                <span>Slots</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
