import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftBarSports from "../../../components/LeftBarSports";

export class terms extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <div>
          <main className="main pagecontent">
            <div className="d-xl-none mt--14">
              <LeftBarSports />
            </div>
            <div className="container">
              <h2 class="sectionTitle"> Terms & Conditions</h2>
              <div className="terms-content">
                <p>
                  Please read these Terms and Conditions ("Terms") carefully
                  before using our betting website ("the Platform"). By
                  accessing or using the Platform, you agree to be bound by
                  these Terms.
                </p>
                <div className="sub-points">
                  <p>
                    <span>Eligibility -</span> You must be of legal gambling age
                    in your jurisdiction to use our Platform. It is your
                    responsibility to ensure compliance with the laws applicable
                    to your location. - You are solely responsible for verifying
                    the legality of online gambling in your jurisdiction and for
                    any consequences arising from your participation.
                  </p>
                  <p>
                    {" "}
                    <span>Account Registration -</span> To use certain features
                    of the Platform, you may be required to create an account.
                    You must provide accurate and complete information during
                    the registration process. - You are responsible for
                    maintaining the confidentiality of your account credentials
                    and for all activities that occur under your account. - You
                    agree to notify us immediately of any unauthorized use or
                    suspected breach of security regarding your account.
                  </p>
                  <p>
                    <span>Responsible Gambling -</span> We are committed to
                    promoting responsible gambling practices and providing tools
                    to help you gamble responsibly. - You may set deposit
                    limits, wagering limits, and session limits on your account
                    to manage your gambling activities. - If you feel that you
                    or someone you know may have a gambling problem, we
                    encourage you to seek assistance from relevant support
                    organizations.
                  </p>
                  <p>
                    {" "}
                    <span>Prohibited Activities -</span> You agree not to engage
                    in any activities that violate applicable laws, regulations,
                    or these Terms while using the Platform. - Prohibited
                    activities include, but are not limited to, fraud, money
                    laundering, unauthorized access, or tampering with the
                    Platform's functionality.
                  </p>
                  <p>
                    {" "}
                    <span>Intellectual Property - </span>All intellectual
                    property rights, including trademarks, logos, and content,
                    displayed on the Platform are owned by us or our licensors.
                    - You are prohibited from using, reproducing, or
                    distributing any copyrighted material or intellectual
                    property without our prior written consent.{" "}
                  </p>
                  <p>
                    {" "}
                    <span>Warranties and Disclaimers -</span> We strive to
                    provide accurate and reliable information on the Platform,
                    but we do not warrant the accuracy, completeness, or
                    reliability of any content or information provided. - The
                    Platform is provided on an "as is" and "as available" basis
                    without any warranties, expressed or implied.
                  </p>
                  <p>
                    <span>Limitation of Liability -</span> In no event shall we
                    be liable for any direct, indirect, incidental,
                    consequential, or punitive damages arising from your use of
                    the Platform or any errors or omissions in its content. - We
                    shall not be responsible for any losses incurred as a result
                    of your gambling activities on the Platform.
                  </p>{" "}
                  <p>
                    {" "}
                    <span>Termination -</span> We reserve the right to suspend,
                    terminate, or restrict your access to the Platform at any
                    time without prior notice, for any reason, including if you
                    violate these Terms.
                  </p>
                  <p>
                    <span>Governing Law and Jurisdiction -</span> These Terms
                    shall be governed by and construed in accordance with the
                    laws of the jurisdiction in which our company is registered.
                    - Any disputes arising out of or in connection with these
                    Terms shall be subject to the exclusive jurisdiction of the
                    courts in that jurisdiction.
                  </p>
                  <p>
                    <span>Severability - </span>If any provision of these Terms
                    is found to be invalid, illegal, or unenforceable, the
                    remaining provisions shall continue to be valid and
                    enforceable to the fullest extent permitted by law. By using
                    our betting website, you acknowledge and agree to these
                    Terms and Conditions. If you have any questions or concerns,
                    please contact our customer support team.
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default terms;
