import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, Button, Container } from "react-bootstrap";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import LeftBarSports from "../../../components/LeftBarSports";

import BackIcon from "../../../../assets/svg/BackIcon";

import DepositImgPromotion from "../../../../assets/images/banner/deposit.jpg";
import FirstDepositImgPromotion from "../../../../assets/images/banner/secondDeposit.jpg";
import SecondDepositImgPromotion from "../../../../assets/images/banner/thirsdeposit.jpg";
import ReferralBonusImgPromotion from "../../../../assets/images/banner/referral.jpg";

const Promotion = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <HeaderAfterLogin /> */}
      <main className="main mt-5">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="container">
          <Container>
            <div className="heading">
              <h4>Promotion</h4>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>

            <div className="promotiion-content">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Deposit Bonus</Accordion.Header>
                  <Accordion.Body>
                    <div className="promotionimg">
                      <img src={DepositImgPromotion} />
                    </div>
                    <h3>First Deposit Bonus :</h3>
                    <p>
                      Get up to 300% bonus on your first deposit as a Parixmatch
                      member. From Deposit of RS 300-5000, Bonus will be 300%
                      From Deposit of RS 5001- 10000, Bonus will be 200% From
                      Deposit of RS 10001- 500000, Bonus will be 100%. NOTE:- A
                      30x turnover of the bonus amount must be completed to
                      activate the bonus.NOTE Maximum Bonus you can achieve is
                      50000.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    SECOND TIME DEPOSIT BONUS :
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="promotionimg">
                      <img src={FirstDepositImgPromotion} />
                    </div>
                    <p>
                      Second time lucky, only on Parixmatch! Now get a 50% bonus
                      on your second deposit as a Parixmatch member. Note: Eg:
                      If the user deposits Rs. 1000, he will receive a bonus
                      worth Rs.500. The bonus will be split into (10 or 100)
                      equal parts and can be activated as he completes the
                      turnover. The required turnover for the second deposit
                      bonus is 15x which will also be split accordingly. NOTE :
                      Maximum bonus you can achieve is 50000.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Third Deposit Bonus :</Accordion.Header>
                  <Accordion.Body>
                    <div className="promotionimg">
                      <img src={SecondDepositImgPromotion} />
                    </div>
                    <p>
                      Third time lucky, only on Parixmatch! Now get a 25% bonus
                      on your third deposit as a Parixmatch member. Note: Eg: If
                      the user deposits Rs. 1000, he will receive a bonus worth
                      Rs.250. The bonus will be split into (10 or 100) equal
                      parts and can be activated as he completes the turnover.
                      The required turnover for the third deposit bonus is 10x
                      which will also be split accordingly. NOTE: Maximum bonus
                      you can achieve is 50000.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Referral Bonus :</Accordion.Header>
                  <Accordion.Body>
                    <div className="promotionimg">
                      <img src={ReferralBonusImgPromotion} />
                    </div>
                    <p>
                      Share your personal referral code with your friend and get
                      a 15% bonus of every single deposit they make thereafter!
                      Note: A.To redeem the bonus amount, the user must complete
                      a turnover of 35x on SPORTSBOOK & CASINO. B. Bonus will be
                      credited after completing the rollover NOTE:-Maximum Bonus
                      you can receive for Referral Bonus is 50000.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Container>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default Promotion;
