import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/games-icon/play3d.png";
import BotHeadIcon2 from "../assets/images/games-icon/cricket-icon.png";
import BotHeadIcon3 from "../assets/images/games-icon/football.png";
import BotHeadIcon4 from "../assets/images/games-icon/plane.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon.png";
import BotHeadIcon6 from "../assets/images/games-icon/cards.png";
import BotHeadIcon7 from "../assets/images/games-icon/fortune-wheel.png";
import { useSelector } from "react-redux";

import SportsIconNew from "../assets/images/games-icon/headicon/sports.svg";
import CashierIconNew from "../assets/images/games-icon/headicon/cashier.svg";
import EzugiIconNew from "../assets/images/games-icon/headicon/roulette.svg";
import EvolutionIconNew from "../assets/images/games-icon/headicon/table-games-new.svg";
import HomeIConNew from "../assets/images/games-icon/headicon/house.png";
import casinoround from "../assets/images/games-icon/headicon/spin_bg.png";
import casinogame from "../assets/images/games-icon/headicon/turntable.png";
import casinopoint from "../assets/images/games-icon/headicon/pointer.png";
import SpribeIconNew from "../assets/images/games-icon/headicon/dice.png";

import WhatsappIconNew from "../assets/images/social/whatsapp.png";
import TelegramIconNew from "../assets/images/social/telegram.png";
import Promotion from "../assets/images/icons/promotion.gif";

import AppDownload from "../assets/images/icons/download1.png";

const Header = ({ betByPublicPage }) => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <header className="header beforeheader">
      <div className="top_head">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>
                <div className="middle-part">
                  <Navbar expand="lg">
                    <Container>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          {/* <Nav.Link href="/sign-in"> */}
                          {/* <HomeIcon /> */}
                          {/* <img src={SportsIconNew} />
                            SportsBook
                          </Nav.Link> */}
                          <Nav.Link href="/sign-in">
                            <img src={SportsIconNew} />
                            Exchange
                          </Nav.Link>
                          {/* <Nav.Link href="sports/cricket" className="">
                            <div>
                              <img src={SportsIconNew} />
                            </div>
                            Sportsbook
                          </Nav.Link>
                          {/* <Nav.Link href="/sports">
                          <img src={Exchange} alt="Exchange Icon" width={20} />{" "}
                          Exchange
                        </Nav.Link> */}
                          <Nav.Link href="/sign-in">
                            {/* <GamesIcon /> */}
                            <div className="animate-img">
                              <div className="enter-animate active">
                                <div className="spin-anim">
                                  <img
                                    src={casinoround}
                                    className="img-spinbg"
                                  />
                                  <img
                                    src={casinogame}
                                    className="img-turntable"
                                  />
                                  <img
                                    src={casinopoint}
                                    className="img-pointer"
                                  />
                                </div>
                              </div>
                            </div>
                            Slots
                          </Nav.Link>
                          <Nav.Link href="/sign-in">
                            {/* <CashierIcon />  */}
                            <img src={EzugiIconNew} />
                            Ezugi
                          </Nav.Link>
                          <Nav.Link href="/sign-in">
                            {/* <CashierIcon />  */}
                            <img src={EvolutionIconNew} />
                            Evolution
                          </Nav.Link>
                          <Nav.Link href="/sign-in">
                            {/* <CashierIcon />  */}
                            <img src={SpribeIconNew} />
                            Spribe
                          </Nav.Link>
                          {/* <Nav.Link href="/promotions">
                            <img src={Promotion} />
                            Promotion
                          </Nav.Link> */}
                          {/* <Nav.Link href="/sign-in">
                            <CashierIcon /> 
                            <img src={CashierIconNew} />
                            Cashier
                          </Nav.Link> */}
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </div>
                <div className="headerRight">
                  <ul className="social">
                    {/* <li>
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "javascript:void(0)"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        {/* <TelegramIcon />}
                        <img src={TelegramIconNew} />
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        {/* <WhatsappIcon /> */}
                        <img src={WhatsappIconNew} />
                      </a>
                    </li>
                  </ul>
                  <li className="app-download" href="">
                    <a
                      href="https://dl.dropbox.com/scl/fi/mvr4otxik4jixdqz0xxgx/app-debug_31july_9_20PM_parimatch.apk?dl=0&rlkey=kyvm2ijlb1avkv77m9wje9ozk"
                      download="app-debug_31july_9_20PM_parimatch.apk"
                      className=" btn"
                      target="__blank"
                    >
                      App <img src={AppDownload} />
                    </a>
                    {/* <Button variant="primary deposit-btn w-100"></Button> */}
                  </li>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    className="button-primary btn_signin"
                  >
                    Log in
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/mobile-number");
                    }}
                    className="button-primary btn_signup btn-secondary ms-2"
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom_head d-none">
        <Container fluid>
          {(!betByPublicPage && (
            <Row>
              <Col xs={12} className="px-0">
                <ul>
                  {/* <li>
                  <a href="/mobile-number">
                    <img
                      src={BotHeadIcon1}
                      alt="bottom head link icon"
                      className="inplay"
                    />
                    <span>Inplay</span>
                  </a>
                </li>*/}
                  {/* <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon2} alt="bottom head link icon" />
                    <span>Cricket</span>
                  </a>
                </li>
                <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon3} alt="bottom head link icon" />
                    <span>Soccer</span>
                  </a>
                </li> */}
                  <li>
                    <a href="/mobile-number">
                      <img src={BotHeadIcon4} alt="bottom head link icon" />
                      <span>Aviator</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mobile-number">
                      <img src={BotHeadIcon5} alt="bottom head link icon" />
                      <span>DragonTiger</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mobile-number">
                      <img src={BotHeadIcon6} alt="bottom head link icon" />
                      <span>TeenPatti</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mobile-number">
                      <img src={BotHeadIcon7} alt="bottom head link icon" />
                      <span>Roulette</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          )) ||
            null}
        </Container>
      </div>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Log in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Log in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
