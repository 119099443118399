import React from "react";
import { useSelector } from "react-redux";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import PlayBtn from "../../../../assets/images/play-btn.png";
import Spribe1 from "../../../../assets/images/spribe/SpribeImg1.jpg";
import Spribe2 from "../../../../assets/images/spribe/SpribeImg2.png";
import Spribe3 from "../../../../assets/images/spribe/SpribeImg3.jpg";
import Spribe4 from "../../../../assets/images/spribe/SpribeImg4.png";
import Spribe5 from "../../../../assets/images/spribe/SpribeImg5.jpg";
import Spribe6 from "../../../../assets/images/spribe/SpribeImg6.png";
import Spribe7 from "../../../../assets/images/spribe/SpribeImg7.png";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/svg/BackIcon";
import LeftBarSports from "../../../components/LeftBarSports";

const Spribe = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="slots-section spribesection">
          <div className="games-section">
            <div className="game-heading d-flex justify-content-between">
              <span>Spribe</span>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>
            <ul>
              <li>
                <a href={isAuth ? "/casino/spribe/mines" : "/login"}>
                  <img src={Spribe1} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/spribe/goal" : "/login"}>
                  <img src={Spribe2} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/spribe/dice" : "/login"}>
                  <img src={Spribe3} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/spribe/aviator" : "/login"}>
                  <img src={Spribe4} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/spribe/plinko" : "/login"}>
                  <img src={Spribe5} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/spribe/miniroulette" : "/login"}>
                  <img src={Spribe6} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/spribe/hilo" : "/login"}>
                  <img src={Spribe7} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Spribe;
