import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftBarSports from "../../../components/LeftBarSports";

export class FairPlay extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <div>
          <main className="main pagecontent">
            <div className="d-xl-none mt--14">
              <LeftBarSports />
            </div>
            <div className="container">
              <h2 class="sectionTitle"> Fair Play</h2>
              <div className="fairplay sub-points">
                <p>
                  At our betting website, we are committed to providing a fair
                  and transparent gambling environment for all our users. We
                  believe in upholding the principles of integrity, honesty, and
                  fair play. To ensure a level playing field, we implement the
                  following measures:
                </p>
                <p>
                  <span>Randomness and Integrity - </span>We use certified and
                  audited random number generator (RNG) technology to determine
                  game outcomes. This ensures that the results are fair,
                  unbiased, and not influenced by any external factors. - We
                  regularly test and verify our RNG systems to maintain the
                  integrity and randomness of the games.
                </p>

                <p>
                  {" "}
                  <span>Anti-Cheating Measures: -</span> We have rigorous
                  security protocols in place to prevent cheating, collusion, or
                  any form of unfair advantage. Our systems detect and
                  investigate suspicious activities to maintain a fair gaming
                  environment. - Any attempts to manipulate or exploit the
                  system, including the use of software or tools to gain unfair
                  advantage, are strictly prohibited and may result in account
                  suspension or legal action.
                </p>
                <p>
                  <span>Responsible Betting -</span> We encourage responsible
                  betting practices among our users. We provide tools and
                  resources to help you gamble responsibly and maintain control
                  over your betting activities. - We have implemented measures
                  to prevent and address problem gambling, including setting
                  deposit limits, wagering limits, and self-exclusion options.
                  These tools promote responsible play and support a healthy
                  gambling experience.
                </p>
                <p>
                  <span>Compliance with Regulations -</span> We adhere to all
                  applicable laws and regulations governing online gambling. We
                  operate within the legal frameworks established by the
                  jurisdictions in which we operate, ensuring compliance with
                  licensing requirements and responsible gambling guidelines.
                </p>
                <p>
                  <span> Player Support and Dispute Resolution -</span> We have
                  a dedicated customer support team to assist you with any
                  concerns or disputes you may have. Our team is committed to
                  addressing your queries promptly and resolving any issues in a
                  fair and impartial manner. - In the event of a dispute, we
                  follow established procedures for investigation and
                  resolution, ensuring transparency and fairness throughout the
                  process. We value the trust and confidence our users place in
                  us, and we strive to maintain the highest standards of fair
                  play. If you have any questions, feedback, or concerns related
                  to fair play on our platform, please do not hesitate to
                  contact our customer support team. We are here to ensure your
                  betting experience is enjoyable, transparent, and fair for
                  all.{" "}
                </p>
              </div>
            </div>
          </main>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default FairPlay;
