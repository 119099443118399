import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import gmail from "../../../../assets/images/gmail.png";
import phonecall from "../../../../assets/images/phone-call.png";
import livechat from "../../../../assets/images/live-chat.png";

import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Twitter from "../../../../assets/images/twitter.png";
import { useDispatch, useSelector } from "react-redux";

import DepositIcon1 from "../../../../assets/images/games-icon/Deposit.png";
import WithdrawIcon1 from "../../../../assets/images/games-icon/Withdraw1.png";
import ProfileIcon1 from "../../../../assets/images/games-icon/profile1.png";
import AccountIcon1 from "../../../../assets/images/games-icon/account-statement1.png";
import SignOut12 from "../../../../assets/images/games-icon/sign-out1.png";
import { logoutUser } from "../../../../redux/auth/actions";
import WhatsappIcon from "../../../../assets/svg/WhatsappIcon";
import LeftBarSports from "../../../components/LeftBarSports";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  console.log("appDetails", appDetails);
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div>
        <main className="main pagecontent afterlogin">
          <div className="d-xl-none mt--14">
            <LeftBarSports />
          </div>
          <div className="container">
            <div className="PageSection">
              <div className="PageRightBar">
                <div className="RightBarHead">
                  <h2>Contact</h2>
                </div>
                <div className="payment-info">
                  <div class="social-icons contact-social d-none">
                    <ul>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          {/* <span className="notification-count">2</span> */}
                          <img src={Whatsapp} alt="Whatsapp Icon" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails.TELEGRAM != ""
                              ? appDetails.TELEGRAM
                              : "javascript:void(0)"
                          }
                          target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={Telegram} alt="Telegram Icon" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails.INSTATGRAM != ""
                              ? appDetails.INSTATGRAM
                              : "javascript:void(0)"
                          }
                          target={appDetails.INSTATGRAM != "" ? "_blank" : ""}
                        >
                          <img src={Instagram} alt="Instagram Icon" />
                        </a>
                      </li>
                      <li>
                        <a className="panel" href="#">
                          <img src={Twitter} alt="Twitter Icon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="other-contact">
                    <p className="foloowingpoints">
                      We appreciate your interest in our betting website and
                      value your feedback. If you have any questions, concerns,
                      or suggestions, our customer support team is here to
                      assist you. There are several ways you can reach out to
                      us:
                    </p>
                    <p className="mt-5">
                      <img src={livechat} className="contact_icons" /> Visit our
                      website and look for the live chat feature located in the
                      bottom corner of the screen. Our knowledgeable support
                      agents will be available to provide real-time assistance
                      during our designated operating hours.
                    </p>
                    <p className="mt-5">
                      <img src={gmail} className="contact_icons" /> You can send
                      us an email at <span>parixmatch@gmail.com </span>Please
                      provide detailed information regarding your inquiry or
                      concern, along with your contact details. We strive to
                      respond to all emails promptly.
                    </p>{" "}
                    <p className="mt-5">
                      <img src={phonecall} className="contact_icons" /> If you
                      prefer to speak with a representative over the phone, you
                      can contact our customer support hotline at{" "}
                      <a href="tel://+917377371777"> +91 73773 71777 </a>. Our
                      support agents will be happy to assist you and answer any
                      questions you may have. Please note that standard call
                      charges may apply.
                    </p>{" "}
                    <p className="mt-5">
                      4. Social Media: You can also reach out to us through our
                      official social media channels. Follow us on{" "}
                      <div className="contact-social">
                        <a
                          className=""
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          {/* <span className="notification-count">2</span> */}
                          <img
                            src={Whatsapp}
                            alt="Whatsapp Icon"
                            className="contact_icons"
                          />
                        </a>{" "}
                        <a
                          className=""
                          href={
                            appDetails.TELEGRAM != ""
                              ? appDetails.TELEGRAM
                              : "javascript:void(0)"
                          }
                          target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img
                            src={Telegram}
                            alt="Telegram Icon"
                            className="contact_icons"
                          />
                        </a>
                        <a
                          className=""
                          href={
                            appDetails.INSTATGRAM != ""
                              ? appDetails.INSTATGRAM
                              : "javascript:void(0)"
                          }
                          target={appDetails.INSTATGRAM != "" ? "_blank" : ""}
                        >
                          <img
                            src={Instagram}
                            alt="Instagram Icon"
                            className="contact_icons"
                          />
                        </a>
                        <a href="https://twitter.com/Dial4W133">
                          {" "}
                          <img src={Twitter} className="contact_icons" />
                        </a>
                      </div>
                      and send us a direct message with your query. We aim to
                      respond to social media inquiries within a reasonable
                      timeframe.
                    </p>
                    <p className="mt-5">
                      5. Contact Form: Alternatively, you can fill out the
                      contact form available on our website. Provide the
                      necessary details and a clear description of your request,
                      and we will get back to you as soon as possible.
                    </p>
                    <p className="mt-5">
                      Please include any relevant account information or details
                      related to your inquiry to help us assist you more
                      efficiently.
                    </p>
                    <p className="mt-5">
                      Our customer support team operates{" "}
                      <span>hours of operation</span>,{" "}
                      <span>days of the week</span>. We are dedicated to
                      providing excellent service and resolving your concerns in
                      a timely manner.
                    </p>
                    <p className="mt-5">
                      Thank you for choosing our betting website. We look
                      forward to assisting you and ensuring you have an
                      enjoyable and seamless betting experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
