import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { APP_CONST } from "../../../../config/const";
import { Toaster } from "../../../../utils/toaster";

import BackIcon from "../../../../assets/svg/BackIcon";
import { AiTwotoneCopy } from "react-icons/ai";

import NEFT from "../../../../assets/images/icons/NEFT.png";
import IMPS from "../../../../assets/images/icons/IMPS.png";
import RTGC from "../../../../assets/images/icons/RTGS.png";
import LeftBarSports from "../../../components/LeftBarSports";

const DepositBank = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main mt-5">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <Container>
          <div className="d-flex justify-content-between mb-4">
            <h2 className="sectionTitle">Bank Transfer</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="deposit-pay-section">
            <div class="mob-deposit-section payment-detials text-center">
              <div class="wallet-app-list mb-4">
                <ul class="nav nav-tabs" id="myTab21" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)">
                      <img src={NEFT} class="img-fluid payment-app" />
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)">
                      <img src={IMPS} class="img-fluid payment-app" />
                    </a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)">
                      <img src={RTGC} class="img-fluid payment-app" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mob-deposit-section">
              <table class="bankTransfer mt-4">
                <tbody>
                  <tr>
                    <td class="fi_tr">Bank Name</td>
                    <td class="text-white">AU SMALL FINANCE BANK</td>
                    <td class="fi_tr copytext">
                      <span>
                        <CopyToClipboard
                          text="AU SMALL FINANCE BANK"
                          onCopy={() => {
                            Toaster(
                              APP_CONST.TOAST_TYPE.SUCCESS,
                              "Text copied"
                            );
                          }}
                        >
                          <AiTwotoneCopy />
                        </CopyToClipboard>
                        <p>Copy</p>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="fi_tr">Account Holder Name</td>
                    <td class="text-white">INFINITY GAMES TECH</td>
                    <td class="fi_tr copytext">
                      <span>
                        <CopyToClipboard
                          text="INFINITY GAMES TECH"
                          onCopy={() => {
                            Toaster(
                              APP_CONST.TOAST_TYPE.SUCCESS,
                              "Text copied"
                            );
                          }}
                        >
                          <AiTwotoneCopy />
                        </CopyToClipboard>
                        <p>Copy</p>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="fi_tr">Account Number</td>
                    <td class="text-white">2221220640231692</td>
                    <td class="fi_tr copytext">
                      <span>
                        <CopyToClipboard
                          text="2221220640231692"
                          onCopy={() => {
                            Toaster(
                              APP_CONST.TOAST_TYPE.SUCCESS,
                              "Text copied"
                            );
                          }}
                        >
                          <AiTwotoneCopy />
                        </CopyToClipboard>
                        <p>Copy</p>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="fi_tr">IFSC Code</td>
                    <td class="text-white">AUBL0002406</td>
                    <td class="fi_tr copytext">
                      <span>
                        <CopyToClipboard
                          text="AUBL0002406"
                          onCopy={() => {
                            Toaster(
                              APP_CONST.TOAST_TYPE.SUCCESS,
                              "Text copied"
                            );
                          }}
                        >
                          <AiTwotoneCopy />
                        </CopyToClipboard>
                        <p>Copy</p>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h3>Important Note</h3>
            <ul class="ul_list">
              <li>
                {" "}
                Please transfer <span>INR</span>
                <span class="pay_amt_">100000.00</span> to above account
                details.
              </li>
              <li>Click continue once the transfer is complete.</li>
              <li>Please share the correct UTR for faster processing.</li>
              <li>
                {" "}
                If you are facing trouble with transfering to the above account
                detsils please reach out to our customer support.{" "}
              </li>
            </ul>

            <Form className="bank-deposit-form mt-3">
              <Form.Group className="form-group mb-4 position-relative">
                <Form.Control
                  type="number"
                  placeholder="Enter Amount"
                  name="amount"
                />
                <span class="focus-border">
                  <i></i>
                </span>
              </Form.Group>

              <button className="btn btn-primary py-2 px-5">Continue</button>
            </Form>
          </div>
        </Container>
      </main>
    </div>
  );
};

export default DepositBank;
