import React from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import LeftBarSports from "../../../components/LeftBarSports";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";

const WithdrawUPIList = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <HeaderAfterLogin />

      <main className="main mt-4">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="d-flex justify-content-end">
          <div className="back-link " onClick={() => navigate(-1)}>
            Back <BackIcon />
          </div>
        </div>
        <div className="container table-responsive">
          <table className="table text-white">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Amount</th>
                <th scope="col">Account Name</th>
                <th scope="col">Account No</th>
                <th scope="col">Ifsc code</th>
                <th scope="col">Bank Name</th>
                <th scope="col">Mobile No</th>
                <th scope="col">status</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>

                <td>500</td>
                <td>54874125956</td>
                <td>5487142569</td>
                <td>PNB1548759854</td>
                <td>Punjab National Bank</td>
                <td>+91 2358945125</td>
                <td>Yes</td>
                <td>22/12/2015</td>
                <td>
                  <Button onClick={showModal}>Cancel</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title className="confirm-title">
            Please confirm to cancel the request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>UPI Withdraw List</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Cancel request</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WithdrawUPIList;
