import React from "react";

export default function CashierIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.628"
      height="25.394"
      viewBox="0 0 24.628 25.394"
    >
      <g
        id="Group_540"
        data-name="Group 540"
        transform="translate(-1061.901 -120.507)"
      >
        <path
          id="Path_1594"
          data-name="Path 1594"
          d="M1062.438,121.065c1.234.168,2.465.294,3.681.509q3.08.545,6.141,1.2a3.839,3.839,0,0,1,1.333.615q4.5,3.1,8.957,6.259a1.743,1.743,0,0,1,.487.592,52.847,52.847,0,0,1,2.924,6.66,1.629,1.629,0,0,1-.022.967c-.515,1.714-1.062,3.418-1.614,5.12a.9.9,0,0,1-.332.4c-.781.569-1.563,1.139-2.366,1.676a1.246,1.246,0,0,1-1.516.137,1.284,1.284,0,0,1-.328-1.519,4.868,4.868,0,0,1,1.469-2.168,1.139,1.139,0,0,0,.335-.617q.173-1.474.257-2.958a1.179,1.179,0,0,0-.226-.708,6.654,6.654,0,0,0-5.481-2.8,4.816,4.816,0,0,0-1.894.347,3.325,3.325,0,0,0-1.954,3.685c.115,1.264.4,2.513.609,3.768a3.421,3.421,0,0,1-.1,1.814,1.9,1.9,0,0,1-1.067,1.164.883.883,0,0,1-1.288-.47c-.737-1.67-1.428-3.361-2.167-5.03a8.49,8.49,0,0,1-.563-3c-.069-1.129-.131-2.258-.226-3.385-.039-.466-.157-.926-.239-1.389a1.771,1.771,0,0,0-1.338-1.449c-.664-.205-1.336-.386-2.005-.574-.42-.118-.845-.217-1.259-.353a.386.386,0,0,1-.229-.269c-.015-2.677-.015-5.354-.017-8.031A1.131,1.131,0,0,1,1062.438,121.065Z"
          fill="none"
          stroke="#ffbd00"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_54"
          data-name="Rectangle 54"
          width="5.997"
          height="1.379"
          transform="translate(1073.438 136.174)"
        />
        <rect
          id="Rectangle_55"
          data-name="Rectangle 55"
          width="5.997"
          height="1.379"
          transform="translate(1073.438 138.024)"
        />
        <rect
          id="Rectangle_56"
          data-name="Rectangle 56"
          width="5.997"
          height="1.379"
          transform="translate(1073.438 139.894)"
        />
        <rect
          id="Rectangle_57"
          data-name="Rectangle 57"
          width="5.997"
          height="1.379"
          transform="translate(1073.438 141.745)"
        />
        <rect
          id="Rectangle_58"
          data-name="Rectangle 58"
          width="5.997"
          height="1.379"
          transform="translate(1073.438 143.605)"
        />
      </g>
    </svg>
  );
}
