import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftBarSports from "../../../components/LeftBarSports";

export class ResponsibleGame extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <div>
          <main className="main pagecontent">
            <div className="d-xl-none mt--14">
              <LeftBarSports />
            </div>
            <div className="container">
              <h2 className="sectionTitle">Responsible Gaming</h2>
              <div className="responsible-game sub-points">
                <p>
                  At our betting website, we are committed to promoting
                  responsible gaming practices and ensuring the well-being of
                  our users. We prioritize the enjoyment and entertainment value
                  of gambling while recognizing the importance of responsible
                  behavior. We provide the following resources and tools to
                  assist you in maintaining control over your gambling
                  activities:
                </p>
                <p>
                  <span>Setting Limits -</span> Deposit Limits: You can set
                  daily, weekly, or monthly deposit limits to control the amount
                  of money you can deposit into your account within a specific
                  timeframe. - Wagering Limits: You have the option to set
                  limits on the amount you can wager during a particular period,
                  helping you manage your betting activities responsibly. - Loss
                  Limits: By setting loss limits, you can define the maximum
                  amount you are willing to lose during a specific period,
                  ensuring you don't exceed your pre-determined threshold.
                </p>
                <p>
                  <span> Time Management - </span>
                  Session Reminders: We provide session reminders to help you
                  keep track of your gambling duration. These reminders serve as
                  prompts to take breaks and maintain a balanced approach to
                  your gambling activities. - Reality Checks: You can enable
                  reality checks, which display notifications at predetermined
                  intervals to remind you of the time spent gambling. This
                  feature helps you stay aware of your gameplay duration.{" "}
                </p>
                <p>
                  <span>Self-Exclusion -</span> If you feel the need to take a
                  break from gambling, we offer self-exclusion options. During
                  the self-exclusion period, you will be unable to access your
                  account and participate in any gambling activities on our
                  platform. - Self-exclusion can be set for a specific duration
                  or indefinitely, depending on your needs. Please reach out to
                  our customer support team to initiate the self-exclusion
                  process.{" "}
                </p>
                <p>
                  <span>Educational Resources -</span> We provide educational
                  materials and information about responsible gaming, including
                  tips for gambling responsibly and recognizing signs of problem
                  gambling. - Our responsible gaming page offers links to
                  support organizations and helpline numbers where you can seek
                  assistance and guidance if you or someone you know is
                  struggling with gambling-related issues.
                </p>
                <p>
                  <span>Underage Gambling -</span> We have strict measures in
                  place to prevent underage gambling. We do not allow
                  individuals under the legal gambling age to access our
                  platform. Age verification checks are conducted during the
                  registration process, and we reserve the right to request
                  additional documentation to verify age and identity. Remember,
                  gambling should be seen as a form of entertainment, and it's
                  essential to gamble responsibly. If you feel that your
                  gambling habits are negatively impacting your life or the
                  lives of those around you, we encourage you to seek help from
                  dedicated support organizations. Our customer support team is
                  also available to assist you with any questions or concerns
                  related to responsible gaming. Please gamble responsibly, and
                  enjoy your betting experience responsibly and in moderation.
                </p>
              </div>
            </div>
          </main>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default ResponsibleGame;
