import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import BackIcon from "../../../../assets/svg/BackIcon";

import NEFT from "../../../../assets/images/icons/NEFT.png";
import IMPS from "../../../../assets/images/icons/IMPS.png";
import RTGC from "../../../../assets/images/icons/RTGS.png";
import LeftBarSports from "../../../components/LeftBarSports";

const WithdrawBank = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main mt-5">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <Container>
          <div className="d-flex justify-content-between mb-4">
            <h2 className="sectionTitle">Bank Transfer (Withdraw)</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="withdraw-bank-section">
            <p>Select a bank account OR Add new One</p>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Sakshi Mehta</Accordion.Header>
                <Accordion.Body>
                  <div className="withdraw-bank-details">
                    <table class="bankTransfer mt-2">
                      <tbody>
                        <tr>
                          <td class="fi_tr">Bank Name</td>
                          <td class="text-white ng-binding">
                            State Bank of India
                          </td>
                        </tr>
                        <tr>
                          <td class="fi_tr">Account Holder Name</td>
                          <td class="text-white ng-binding">Aditya Dixit</td>
                        </tr>
                        <tr>
                          <td class="fi_tr">Account Number</td>
                          <td class="text-white ng-binding">30857779247</td>
                        </tr>
                        <tr>
                          <td class="fi_tr">IFSC Code</td>
                          <td class="text-white ng-binding">SBIN0010467</td>
                        </tr>
                      </tbody>
                    </table>
                    <button className="btn btn-primary select-account py-2 px-5">
                      Select Account
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Add New Account</Accordion.Header>
                <Accordion.Body>
                  <div className="withdraw-bank-details">
                    <button className="btn btn-primary select-account py-2 px-5">
                      Continue
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <h3>Important Note</h3>
            <ul class="ul_list">
              <li>
                {" "}
                Please transfer <span>INR</span>
                <span class="pay_amt_">100000.00</span> to above account
                details.
              </li>
              <li>Click continue once the transfer is complete.</li>
              <li>Please share the correct UTR for faster processing.</li>
              <li>
                {" "}
                If you are facing trouble with transfering to the above account
                detsils please reach out to our customer support.{" "}
              </li>
            </ul>

            <Form className="bank-deposit-form mt-3">
              <Form.Group className="form-group mb-4 position-relative">
                <Form.Control
                  type="number"
                  placeholder="Enter Amount"
                  name="amount"
                />
                <span class="focus-border">
                  <i></i>
                </span>
              </Form.Group>

              <Form.Group className="form-group mb-4 position-relative">
                <Form.Control
                  type="text"
                  placeholder=" Account Name"
                  name="amount"
                />
                <span class="focus-border">
                  <i></i>
                </span>
              </Form.Group>

              <Form.Group className="form-group mb-4 position-relative">
                <Form.Control
                  type="number"
                  placeholder=" Account Number"
                  name="amount"
                />
                <span class="focus-border">
                  <i></i>
                </span>
              </Form.Group>
              <Form.Group className="form-group mb-4 position-relative">
                <Form.Control
                  type="text"
                  placeholder=" Account IFSC Code"
                  name="amount"
                />
                <span class="focus-border">
                  <i></i>
                </span>
              </Form.Group>

              <Form.Group className="form-group mb-4 position-relative">
                <Form.Control
                  type="text"
                  placeholder=" Account Bank Name"
                  name="amount"
                />
                <span class="focus-border">
                  <i></i>
                </span>
              </Form.Group>
              <Form.Group className="form-group mb-4 position-relative">
                <Form.Control
                  type="number"
                  placeholder=" Mobile Number"
                  name="amount"
                />
                <span class="focus-border">
                  <i></i>
                </span>
              </Form.Group>
              <button className="btn btn-primary py-2 px-5">
                Request Withdraw
              </button>
            </Form>
          </div>
        </Container>
      </main>
    </div>
  );
};

export default WithdrawBank;
