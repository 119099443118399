import React from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BackIcon from "../../../../assets/svg/BackIcon";
import BankImg from "../../../../assets/images/icons/bank.png";

const Cashier = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main cashiersection mt-5">
        <Container>
          <div className="d-flex justify-content-between mb-4">
            <h2 className="sectionTitle">Cashier</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="your-balance-section">
            <div>
              <div className="head-balance">
                <span>Your Balance</span>
                <span id="yourBalance">2261.95 INR</span>
              </div>
            </div>
            <div>
              <h3>Welcome,</h3>
              <p>View your account details here.</p>
            </div>
          </div>

          <div className="deposit-withdraw">
            <Tabs defaultActiveKey="first">
              <Tab eventKey="first" title="Deposit">
                <div className="deposit-pay-section">
                  <h4 class="pay-head">Payment Method</h4>
                  <div className="mob-deposit-section">
                    <a href="/deposit-bank">
                      <div className="box-section">
                        <div class="box-child">
                          <div class="box-icon">
                            <img src={BankImg} class="img-fluid pay_icon1" />
                          </div>
                          <p class="">Bank Transfer</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="second" title="Withdraw">
                <div className="deposit-pay-section">
                  <h4 class="pay-head">Payment Method</h4>
                  <div className="mob-deposit-section">
                    <a href="/withdraw-bank">
                      <div className="box-section">
                        <div class="box-child">
                          <div class="box-icon">
                            <img src={BankImg} class="img-fluid pay_icon1" />
                          </div>
                          <p class="">Bank Transfer</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </main>
    </div>
  );
};

export default Cashier;
