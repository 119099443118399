import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftBarSports from "../../../components/LeftBarSports";

function PrivacyPolicy() {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div>
        <main className="main pagecontent">
          <div className="d-xl-none mt--14">
            <LeftBarSports />
          </div>
          <div className="container">
            <h2 className="sectionTitle"> Privacy Policy</h2>
            <div className="privay-content sub-points">
              <p>
                At our betting website, we value your privacy and are committed
                to protecting your personal information. This Privacy Policy
                outlines how we collect, use, and safeguard the data you provide
                when using our platform. We encourage you to read this policy
                carefully to understand how we handle your information.
              </p>
              <p>
                <span>Information We Collect:</span>
                Personal Information: During the registration process, we may
                collect your name, email address, contact details, and other
                relevant information. - Financial Information: If you engage in
                financial transactions on our platform, such as deposits and
                withdrawals, we may collect certain financial information, such
                as payment card details or bank account information. - Usage
                Information: We gather data on your interactions with our
                website, including IP addresses, browser type, device
                information, and pages visited. This helps us enhance our
                services and improve user experience.
              </p>
              <p>
                <span>Use of Information:</span>
                Personalization: We may use your information to personalize your
                experience on our platform, provide tailored offers, and
                recommend relevant content. - Account Management: Your
                information is utilized to manage your account, process
                transactions, and communicate with you regarding account-related
                activities. - Security and Fraud Prevention: We employ your data
                to maintain the security of our platform, detect and prevent
                fraudulent activities, and ensure compliance with legal
                obligations. - Analytics and Improvements: We analyze user
                trends and behavior to improve our services, enhance website
                functionality, and optimize user experience.
              </p>
              <p>
                <span>Data Sharing:</span>
                Third-Party Service Providers: We may share your information
                with trusted third-party service providers who assist us in
                delivering our services, including payment processors, customer
                support tools, and analytics platforms. These parties are bound
                by confidentiality agreements and are prohibited from using your
                data for any other purpose. - Legal Requirements: We may
                disclose your information if required by law or in response to
                valid legal requests, such as court orders or government
                inquiries.
              </p>
              <p>
                <span>Security Measures:</span>
                We employ industry-standard security measures to protect your
                information from unauthorized access, misuse, or loss. This
                includes encryption, firewalls, secure server infrastructure,
                and regular system updates.
              </p>
              <p>
                <span>Cookies and Tracking Technologies:</span>
                We use cookies and similar tracking technologies to enhance your
                browsing experience, analyze user behavior, and provide
                personalized content. You have the option to manage your cookie
                preferences through your browser settings.
              </p>
              <p>
                <span>Third-Party Links:</span>
                Our website may contain links to third-party websites or
                services. We are not responsible for the privacy practices or
                content of these external sites. We encourage you to review
                their respective privacy policies.
              </p>
              <p>
                <span>Children's Privacy:</span>Information placed on your
                device
              </p>
              <p>
                Our platform is intended for individuals who are of legal
                gambling age. We do not knowingly collect personal information
                from individuals under the age of 18. If we become aware of such
                information being provided, we will take appropriate steps to
                delete it.
              </p>
              <p>
                <span>Updates to the Privacy Policy:</span>
                We reserve the right to update this Privacy Policy periodically.
                We will notify you of any material changes by posting the
                updated policy on our website or through other means of
                communication.
              </p>
              <p>
                By using our betting website, you consent to the collection,
                use, and disclosure of your information as described in this
                Privacy Policy. If you have any questions or concerns regarding
                your privacy or data protection, please contact our customer
                support team.
              </p>
            </div>
          </div>
        </main>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
